<template>
    <div class="card b0 mb-2 card-default card-accordion">
        <div class="card-header" @click="open = !open">
            <h4 class="card-title">
                <a class="text-inherit">
                    <small>
                        <em class="fad fa-chevron-double-down mr-2" v-show="!open"></em>
                        <em class="fad fa-chevron-double-up mr-2" v-show="open"></em>
                    </small>
                    <span class="align-middle"><slot name="title"></slot></span>
                </a>
            </h4>
        </div>
        <b-collapse :id="id" v-model="open">
            <div class="card-body">
               <slot></slot>
            </div>
        </b-collapse>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                open: false,
                id: null
            };
        },
        mounted () {
            this.id = 'acc_' + this._uid
        }
    }
</script>
