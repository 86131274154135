<template>
    <form @submit.prevent="submit()" ref="form" :class="loading && !hideConfirmButton ? 'whirl' : ''">

        <div>
            <span class="mr-3">{{$t('common.component.form.address_form.form.address.type.label')}}:</span>
            <span class="mr-3 badge badge-secondary">{{$t('common.component.form.address_form.form.address.type.option.'+ model.type +'.label')}} <span v-if="!model.locked">(<a href="" @click.prevent="changeType()">{{ $t('common.component.form.address_form.button.change_type.label') }}</a>)</span></span>
        </div>
        <hr class="my-2"/>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.first_name.label')}}</label>
                    <input class="form-control" v-model="model.firstname" required type="text" :disabled="model.locked || model.locked_soft"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.last_name.label')}}</label>
                    <input class="form-control" v-model="model.lastname" required type="text" :disabled="model.locked || model.locked_soft"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>{{ $t('common.component.form.address_form.form.address.birthdate.label')}}</label>
            <datepicker :use-utc="true"
                        :bootstrap-styling="true"
                        v-model="model.birthdate" name="birthdate"
                        class="is-invalid border-right-0"
                        :required="true" :disabled="model.locked || model.locked_soft"
                        initial-view="year" :open-date="new Date(1995,1,0)"
                        placeholder="Enter birthdate"
                        :format="'yyyy-MM-dd'" :typeable="true"/>
        </div>

        <div class="form-group" v-if="model.type === 'company'">
            <label>{{$t('common.component.form.address_form.form.address.company_name.label')}}</label>
            <input class="form-control" v-model="model.company" required type="text" :disabled="model.locked"/>
        </div>
        <div class="form-group" v-if="model.type === 'company'">
            <label>USt-ID</label>
            <input class="form-control" v-model="model.ustid" type="text" :disabled="model.locked"/>
        </div>

        <div class="row">
            <div class="col-sm-8">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.street.label')}}</label>
                    <input class="form-control" v-model="model.street" required type="text" :disabled="model.locked"/>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.house_number.label')}}</label>
                    <input class="form-control" v-model="model.house_number" required type="text" :disabled="model.locked"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.address_extras.label')}}</label>
                    <input class="form-control" v-model="model.address_extras" type="text" :disabled="model.locked"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.postal_code.label')}}</label>
                    <input class="form-control" v-model="model.zip" required type="text" :disabled="model.locked"/>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group">
                    <label>{{$t('common.component.form.address_form.form.address.city.label')}}</label>
                    <input class="form-control" v-model="model.town" required type="text" :disabled="model.locked"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <b-row>
                <b-col sm="6">
                    <label>{{$t('common.component.form.address_form.form.address.country.label')}}</label>
                    <country-select class="form-control" v-model="model.country" :country="model.country" topCountry="CH" :disabled="model.locked" required/>
                </b-col>
                <b-col sm="6" v-if="model.country">
                    <label>{{$t('common.component.form.address_form.form.address.state.label')}}</label>
                    <region-select  class="form-control" v-model="model.state" :country="model.country" :region="model.state" :disabled="model.locked" required/>
                </b-col>
            </b-row>
        </div>

      <div class="btn-toolbar justify-content-end">
        <button class="btn btn-info" v-show="!hideConfirmButton && !model.locked" type="submit" :disabled="loading">{{$t('common.component.form.address_form.button.update_address_changes.label')}}</button>
      </div>

    </form>
</template>
<script>


    import Datepicker from 'vuejs-datepicker';
    import SlotEntityForm from "../../modules/Common/components/SlotEntityForm";

    export default {
        extends: SlotEntityForm,
        components: {
            Datepicker
        },
        data() {
            return {
                model: {
                    type: 'private'
                },
                apiEndpoint: 'user/address'
            }
        },
        created() {
            this.load().then(() => {
                if (this.model.id) {
                    this.$emit('address-detected', this.model);
                }
            });
        },
        methods: {
            changeType() {
                if (this.model.type === 'private') {
                    this.model.type = 'company';
                } else {
                    this.model.type = 'private';
                }
            },
            afterSave() {
                this.$store.commit('addressIsFilled');
                this.$emit('address-filled');
            }
        }
    }
</script>
