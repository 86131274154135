<template>

  <div>

<div :class="loading ? 'whirl' : ''">


    <div v-if="status === 'approved'">
      <div class="alert alert-primary">KYC Aktiv -  Alle Funktionen sind freigeschalten</div>

      <address-form hideConfirmButton="true" ref="addressForm" entity-slot="residency"></address-form>
    </div>

    <div v-else-if="status === 'requested'">
      <div class="alert alert-primary">Verifizierung eingereicht, bitte warte ein paar Tage auf die Prüfung.</div>
    </div>

    <div v-else-if="!loading">

      <div class="alert alert-warning"><b>Warum wurde meine Verifizierung zurückgesetzt?</b> Wir haben uns von unserem alten externen KYC-Provider getrennt und konnten unsere Daten nicht exportieren/übernehmen. Daher müssen wir eine erneute verifizierung durchführen.</div>

      <div>

      <p>Anleitung:</p>

      <p>1. Alle Dokumente und Fotos müssen mit dem Smartphone fotografiert werden (kein Scanner oder PDF).</p>
      <p>2. Fotografiere deinen Ausweis <b>oder</b> Reisepass von beiden Seiten.</p>
      <p>3. Fotografiere einen <b>Adressnachweis.</b></p>
        <accordion-card>
          <template v-slot:title>
            Guide: Welcher Addressnachweis ist gültig?
          </template>
          <p>Gültig wenn <b>maximal 3 Monate alt:</b></p>

          <ul>
            <li>Verbrauchsabrechnung (Strom, Gas, Wasser, Heizöl, ...)</li>
            <li>Steuerbescheid</li>
            <li>Lohnabrechnung</li>
            <li>Kontoauszug</li>
            <li>Kreditkartenrechnung</li>
            <li>Mietvertrag</li>
          </ul>

          <br/>

          <p>Dokumente, die <b>nicht</b> als Adressnachweis gelten</p>

          <ul>
            <li>Rentenbescheinigung</li>
            <li>Bankreferenz</li>
            <li>Versicherungsunterlagen</li>
            <li>Übertragungsdokument</li>
            <li>Irgendwelche Schreiben der Polizei oder von sonstigen Behörden</li>
            <li>Irgendwelche Rechnungen oder Briefe</li>
            <li>Mobilfunkrechnung</li>
            <li>Alter Kontoauszug (älter als drei Monate)</li>
          </ul>
        </accordion-card>
      <p>4. Fotografiere einen <b>Echtheitsnachweis</b> in dem du auf ein Papier "CannerGrow" mit dem aktuellen Datum schreibst und ein Selfi von dir machst, halte dabei mit einer Hand gut Sichtbar das Papier mit dem Ausweis oder Reisepass in der Hand.</p>

        <p>5. Prüfe deine Adresse oder fülle sie aus falls noch nicht vorhanden, diese muss mit dem Addressnachweis übereinstimmen.</p>
      <p>6. Lade alle Bilder auf dieser Seite hoch.</p>
      <p>7. Sende die Verifizierungsanfrage ab</p>

    </div>

    <hr/>

    <address-form hideConfirmButton="true" ref="addressForm" entity-slot="residency"></address-form>

    <hr/>

    <div class="border border-primary p-3" :class="uploading ? 'whirl' : ''">

      <p>Dokument Hochladen</p>

      <select class="form-control" v-model="selectedSlot">
        <option v-for="(tran, key) in translation" :value="key" selected v-bind:key="key">{{ tran }}</option>
      </select>
      <div class="input-group mt-2">
        <input type="file" ref="uploadInput" class="form-control" id="UploadFile" aria-describedby="UploadFile" aria-label="Upload">
        <button @click="submitFile" class="btn btn-primary" type="button" id="UploadFile">Upload File</button>
      </div>


      <ul class="list-group mt-2 border border-primary">

        <li class="list-group-item">
          <div class="text-primary" v-if="(documents.id_frontside && documents.id_backside) || (documents.passport_frontside && documents.passport_backside)"><i class=" mr-2 fas fa-check"></i>Ausweis oder Reisepass</div>
          <div class="text-danger" v-else><i class="mr-2 fas fa-times"></i>Ausweis oder Reisepass</div>
        </li>

        <li class="list-group-item">
          <div class="text-primary" v-if="documents.proof_residency"><i class=" mr-2 fas fa-check"></i>Adressnachweis</div>
          <div class="text-danger" v-else><i class="mr-2 fas fa-times"></i>Adressnachweis</div>
        </li>

        <li class="list-group-item">
          <div class="text-primary" v-if="documents.proof_face"><i class=" mr-2 fas fa-check"></i>Echtheitsnachweis</div>
          <div class="text-danger" v-else><i class="mr-2 fas fa-times"></i>Echtheitsnachweis</div>
        </li>

        <li class="list-group-item" v-if="isCompany()">
          <div class="text-primary" v-if="documents.proof_company"><i class=" mr-2 fas fa-check"></i>Gewerbeschein/Gründungsurkunde</div>
          <div class="text-danger" v-else><i class="mr-2 fas fa-times"></i>Gewerbeschein/Gründungsurkunde</div>
        </li>

      </ul>


    </div>

    <hr/>

    <div>

      <p>Deine Dokumente für die Verifizierung</p>

      <div class="row">
        <div class="col-sm-4" v-for="document in documents" v-bind:key="document.id">
          <div class="card">
            <img v-if="document.thumbnail !== 0" class="card-img-top" :src="document.thumbnail">
            <div class="card-body">
              <div class="badge bg-primary">{{ translation[document.slot] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr/>

    <div>

      <div class="mt-3 text-right">
        <button class="btn btn-primary" :disabled="!canRequest()" @click="sendRequest()">Verifizierung absenden</button>
      </div>

    </div>

  </div>

  </div>
  </div>

</template>
<script>
import AddressForm from "@/components/Forms/AddressForm.vue";
import AccordionCard from "@/modules/Growing/components/AccordionCard.vue";

export default {
  components: {AccordionCard, AddressForm},
  data() {
    return {
      translation: {
          id_frontside: 'Ausweis Vorderseite',
          id_backside: 'Ausweis Rückseite',
          passport_frontside: 'Reisepass Vorderseite',
          passport_backside: 'Reisepass Rückseite',
          proof_residency: 'Addressnachweis',
          proof_face: 'Echtheitsnachweis',
          proof_company: 'Gewerbeschein/Gründungsurkunde'
      },
      documents: {},
      status: null,
      selectedSlot: 'id_frontside',
      uploading: false,
      loading: true
    }
  },
  mounted() {
    this.$api.get('verification/verification').then((response) => {

      if (response.data.documents) {
        response.data.documents.forEach((document) => {
          this.$set(this.documents, document.slot, document)
        })
      }

      this.status = response.data.status;

      this.loading = false;
    })
  },
  methods: {
    submitFile() {
      this.uploading = true;
      let rawDocument = this.$refs.uploadInput.files[0];

      const formData = new FormData();
      formData.append('document', rawDocument);
      formData.append('slot', this.selectedSlot);
      const headers = {'Content-Type': 'multipart/form-data'};

      this.$api.post('verification/verification/upload-document', formData, {headers}).then((response) => {
        this.$set(this.documents, response.data.slot, response.data);
        this.uploading = false;
      });
    },
    canRequest() {
      return this.documents.proof_face
          && this.documents.proof_residency
          && ((this.documents.id_frontside && this.documents.id_backside) || (this.documents.passport_frontside && this.documents.passport_backside))
          && (this.isCompany() ? this.documents.proof_company : true)
    },
    isCompany() {
      if (this.$refs.addressForm) {
        return this.$refs.addressForm.model.type === 'company';
      } else {
        return false;
      }
    },
    sendRequest() {
      this.loading = true;
      this.$refs.addressForm.submit().then(() => {
        this.$api.post('verification/verification/send-request').then(() => {
          this.$swal.fire('Verifizierung eingereicht', 'die Verifizierung kann bis zu 2-3 Wochen gehen!', 'success');
          this.$router.push({name: 'Dashboard'});
        })
      }).catch(function (response) {
        //browser based error message thrown here
      });
    }
  }
}
</script>