<script>
    export default {
        props: ['hideConfirmButton', 'entitySlot'],
        data() {
            return {
                model: null,
                loading: true,
                apiEndpoint: ''
            }
        },
        created() {
            this.load();
        },
        methods: {
            submit() {
                if (!this.$refs.form.checkValidity()) {
                    this.$refs.form.reportValidity();
                    return Promise.reject('invalid');
                } else {
                    if (!this.model.locked) {
                        return this.update();
                    } else {
                        //fake update response if locked with old data
                        return Promise.resolve(this.model);
                    }
                }
            },
            update() {
                this.loading = true;
                return this.$api.update(this.apiEndpoint, Object.assign(this.model,{slot: this.entitySlot})).then((response) => {
                    this.model = response.data;
                    if (!this.hideConfirmButton) {
                        this.$swal.fire('Saved!', '', 'success');
                    }
                    this.afterSave();
                    this.loading = false;
                    return response.data;
                }).catch(response => {

                  if (Object.keys(response.data.errors).length > 0) {
                    this.$swal.fire('Validation error', response.data.errors[Object.keys(response.data.errors)[0]][0], 'error');
                  } else {
                    this.$swal.fire('Validation error', response.data.message, 'error');
                  }

                    this.loading = false;

                    if (this.hideConfirmButton) {
                        throw response;
                    }
                });
            },
            load() {
                this.loading = true;
                return this.$api.get(this.apiEndpoint, {slot: this.entitySlot}).then((response) => {
                    if (response.data.id) {
                        this.model = response.data;
                    }
                    this.loading = false;
                });
            },
            afterSave() {

            }
        }
    }
</script>