<template>
    <div class="card card-default">
        <div class="card-header bg-gray-lighter text-bold">{{ $t('common.view.settings.verification.heading') }}</div>
        <div class="card-body">

          <verification-request></verification-request>

        </div>
    </div>
</template>

<style lang="scss">


</style>
<script>

    import VerificationRequest from "@/modules/Verification/components/VerificationRequest.vue";

    export default {
        components: {
          VerificationRequest
        },
        data() {
            return {

            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>